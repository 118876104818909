<template>
  <Navbar/>
  <Navbar/>
  <div style='width: 100%;height: 100%;position: absolute;min-width: 375px' class='home_content_div'>
    <div class='home_top_div'>
      <div class=''><img class='home_img_mobile' :src='topImg1'></div>
      <div class='homeImg-div2'><img class='home_img_mobile' :src='topImg2'></div>
    </div>
    <div class='home_top_div2'>
      <div class=''><img class='home_img_mobile' :src='topImg2in1'></div>
      <div class='homeImg-div2'><img class='home_img_mobile' :src='topImg2in2'></div>
      <div class='homeImg-div2'><img class='home_img_mobile' :src='topImg2in3'></div>
      <div class='homeImg-div2'><img class='home_img_mobile' :src='topImg2in4'></div>
      <div class='homeImg-div2'><img class='home_img_mobile' :src='topImg2in5'></div>
    </div>
    <div class='home_top_div3'>
      <div class=''><img class='home_img_mobile' :src='topImg3in4'><img class='home_img_mobile' :src='topImg3in1'></div>
      <div class='homeImg-div2'><img class='home_img_mobile' :src='topImg3in2'></div>
      <div class='homeImg-div2'><img class='home_img_mobile' :src='topImg3in3'></div>
    </div>
    <div class='home_top_div4'>
      <div class='' style='margin-left: 15px;'><img class='home_img_mobile' :src='topImg4in1'></div>
      <div class='homeImg-div2' style='margin-left: 7px;'><img class='home_img_mobile' :src='topImg4in2'></div>
      <div class='homeImg-div2'><img class='home_img_mobile' :src='topImg4in3'></div>
      <div class='homeImg-div2' style='margin-left: 4px;'><img class='home_img_mobile' :src='topImg4in4'></div>
      <div class='homeImg-div2'><img class='home_img_mobile' :src='topImg4in5'></div>
    </div>
    <div class='home_top_div4-1'>
      <div class='top_img_div4-1'><img class='home_img_mobile' :src='topImg4in6'></div>
    </div>
    <div class='home_top_div5'>
      <div>
        <div class='hover-box' @mouseenter="hovering = true" @mouseleave="hovering = false"><img class='home_img_mobile' :src='topImg5in1'></div>
        <div class='homeImg-div2 hover-box' @mouseenter="hovering = true" @mouseleave="hovering = false"><img class='home_img_mobile' :src='topImg5in2'></div>
      </div>
      <div>
        <div class='homeImg-div2 hover-box' @mouseenter="hovering = true" @mouseleave="hovering = false"><img class='home_img_mobile' :src='topImg5in3'></div>
        <div class='homeImg-div2 hover-box' @mouseenter="hovering = true" @mouseleave="hovering = false"><img class='home_img_mobile' :src='topImg5in4'></div>
        <div class='homeImg-div2 hover-box' @mouseenter="hovering = true" @mouseleave="hovering = false"><img class='home_img_mobile' :src='topImg5in5'></div>
      </div>
      <div>
        <div class='homeImg-div2'><img class='home_img_mobile' :src='topImg5in6'></div>
      </div>
      <div>
        <div style='    margin-top: 25px;'>
          <div class='line-div'>
            <!--          <div></div>-->
            <span>认知自我</span>
            <img style='width: 30%' :src='topImg5in7'><span>选择未来</span>
            <!--          <div></div>-->
          </div>
        </div>
      </div>
    </div>
    <Bottom />
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import Navbar from '@/layout/components/appMobile/Navbar'
import Bottom from '@/layout/components/Bottom'
const store = useStore()
const name = ref(store.getters.userInfo.name)
const logout = () => {
  store.dispatch('user/logout')
}
const topImg1 = require('../../assets/images/home/1-1.png')
const topImg2 = require('../../assets/images/home/1-2.png')
const topImg3 = require('../../assets/images/home/1-3.png')
const topImg4 = require('../../assets/images/home/1-4.png')

const topImg2in1 = require('../../assets/images/home/2-1.png')
const topImg2in2 = require('../../assets/images/home/2-2.png')
const topImg2in3 = require('../../assets/images/home/2-3.png')
const topImg2in4 = require('../../assets/images/home/2-4.png')
const topImg2in5 = require('../../assets/images/home/2-5.png')

const topImg3in1 = require('../../assets/images/home/3-1.png')
const topImg3in2 = require('../../assets/images/home/3-2.png')
const topImg3in3 = require('../../assets/images/home/3-3.png')
const topImg3in4 = require('../../assets/images/home/3-4.png')

const topImg4in1 = require('../../assets/images/home/4-1.png')
const topImg4in2 = require('../../assets/images/home/4-2.png')
const topImg4in3 = require('../../assets/images/home/4-3.png')
const topImg4in4 = require('../../assets/images/home/4-4.png')
const topImg4in5 = require('../../assets/images/home/4-5.png')
const topImg4in6 = require('../../assets/images/home/4-6.png')

const topImg5in1 = require('../../assets/images/home/5-1.png')
const topImg5in2 = require('../../assets/images/home/5-2.png')
const topImg5in3 = require('../../assets/images/home/5-3.png')
const topImg5in4 = require('../../assets/images/home/5-4.png')
const topImg5in5 = require('../../assets/images/home/5-5.png')
const topImg5in6 = require('../../assets/images/home/5-6.png')
const topImg5in7 = require('../../assets/images/home/5-7.png')
const hovering = ref(false)

const isUserInfo = ref(false)
if (name.value !== null && name.value !== undefined && name.value !== '') {
  isUserInfo.value = true
}
</script>

<style lang="scss" scoped>
.navbarHome {
  height: $navbarHeight;
  overflow: hidden;
  position: fixed;
  width: 100%;
  background: $background-color-primary;
  //box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  border-bottom: $border-base;
  text-align: center;
  z-index: 1000;
  .newbtn{
    margin-top:9px;
    margin-left:25px;
  }
  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    // hover 动画
    transition: background 0.5s;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    align-items: center;
    display: inline-block;
    padding-right: 16px;
    position: fixed;
    /* top: -160px; */
    /* left: 300px; */
    right: 0;
    top: 0;

    :deep(.right-menu-item) {
      display: inline-block;
      padding: 0 5px 0 0;
      font-size: 24px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;
        line-height: 60px;

        //&:hover {
        //  background: rgba(0, 0, 0, 0.025);
        //}
      }
    }

    :deep(.avatar-container) {
      cursor: pointer;
      .avatar-wrapper {
        margin-top: 5px;
        position: relative;
        .el-avatar {
          --el-avatar-background-color: none;
          margin-right: 12px;
        }
      }
    }
  }

  .avatar-container{
    line-height:$navbarHeight;

    .avatar-wrapper {
      margin-top:0px !important;
    }
  }
}
.home_top_div{
  width: 100%;
  //height: 186vw;
  background: rgb(164,200,255);
  text-align: center;
  padding: 7vw 0
}
.top_img_div{
  display: inline-block;
  margin: 40px 80px;
}
.home_top_div2{
  width: 100%;
  //height: 520vw;
  background: rgb(161,223,244);
  text-align: center;
  //padding-top: 40px;
  padding: 7vw 0
}
.top_img_div2in1{
  //position: fixed;
  //margin: 0 auto;
  height: 100%;
  display: grid; /* 设置容器为grid布局 */
  place-items: center; /* 同时水平和垂直居中对齐子项目 */

}
.top_img_div2{
  display: inline-block;
  margin: 40px 80px;
}
.home_top_div3{
  background: rgb(164,200,255);
  width: 100%;
  //height: 440vw;
  text-align: center;
  padding: 7vw 0
}
.top_img_div3{
  display: inline-block;
  margin: 80px 30px;
}
.home_top_div4{
  width: 100%;
  //height: 1900px;
  background: rgb(161,223,244);
  text-align: center;
  padding: 7vw 0
}
.top_img_div4{
  display: inline-block;
  margin: 65px 80px;
}
.home_top_div4-1{
  width: 100%;
  //height: 300px;
  background: rgb(225,227,231);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 15vw 0
}
.top_img_div4-1{
  //display: inline-block;
  //margin: 40px 80px;
}
.home_top_div5{
  width: 100%;
  //height: 2500px;
  background: rgb(161,223,244);
  text-align: center;
  padding: 7vw 0

}
.home_img_div5{
  display: inline-block;
  margin: 50px 80px;
}
.hover-box {
  transition: transform 0.3s;
  transform: translateY(0);
}

.hover-box:hover {
  transform: translateY(-10px);
}
.homeImg-div1{
  margin-top: 40px;
}
.homeImg-div2{
  margin-top: 70px;
}
.home_img_mobile{
  width: 95%;
}
.line-div{
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    width: 30px;
    border-top: 1px solid white;
  }
}
.line-div span{
  color: white;
  font-size: 14px;
  margin: 0 28px;
  font-weight: bold;
}
</style>
